import { ref } from 'vue'

import { Charge } from "@tolemac/grpc_web_api/tolemac/pub/model"

import { syncLazyResources, useValidation } from '@tolemac/web-components'

import { monthBetween, startOfMonthUTC } from '@/util'

import { useApi } from '../api'
import { validators } from './util.validators'

type _Charge = DeepRequired<Charge, ['values', 'beginDate']>

export const charges = {

    useCharges() {
        const $api = useApi()

        const valid = useValidation([] as _Charge[], validators.charges)
        const chargesCount = ref<number>()

        async function load() {
            await $api.charge.find({
                relations: ['values']
            })
                .then(v => {
                    valid.setVal(v.values as _Charge[])
                    chargesCount.value = v.count
                })
        }

        function summarize() {
            const start = startOfMonthUTC()
            return valid.refObj.value.reduce((tot, curr) => {
                if (curr.type === Charge.ChargeType.VARIABLE) {
                    const added = curr.values.find(v => v.beginDate <= start && (!v.endDate || v.endDate >= start))
                    return {
                        fix: tot.fix,
                        var: tot.var + (added?.value || 0)
                    }
                } else {
                    const activeIndex = findIndexOfActiveValue(curr, start)
                    return {
                        fix: tot.fix + (activeIndex !== -1 ? curr.values[activeIndex].value || 0 : 0),
                        var: tot.var
                    }
                }
            }, { fix: 0, var: 0 })
        }

        const { sync } = syncLazyResources(
            $api.charge.add.bind($api.charge),
            $api.charge.update.bind($api.charge),
            $api.charge.delete.bind($api.charge),

            valid
        )

        return {
            valid,

            charges: valid.refObj,
            charges$: valid.validationObj,
            chargesCount: chargesCount,

            load,
            summarize,
            sync
        }
    },

    findIndexOfActiveValue,
    chargeFrequencyToMonth
}

/**
 * Find first active value between start date (include) and end date (include).
 * return index of active value or -1
 */
function findIndexOfActiveValue(charge: Charge, start: Timestamp) {

    if (charge.exclude && charge.exclude.indexOf(start) !== -1) return -1

    return (charge.values || []).findIndex(v => {
        // is this value active on this month
        if (v.beginDate! <= start && (!v.endDate || v.endDate! >= start)) {
            return charge.frequency === undefined
                || charge.frequency === Charge.ChargeFrequency.MONTHLY
                || charge.frequency === Charge.ChargeFrequency.UNDEFINED_ChargeFrequency
                || monthBetween(v.beginDate, start) % chargeFrequencyToMonth(charge.frequency) === 0
        }
    })
}

function chargeFrequencyToMonth(frequency: Charge.ChargeFrequency) {
    switch (frequency) {
        case Charge.ChargeFrequency.MONTHLY: return 1
        case Charge.ChargeFrequency.QUARTERLY: return 3
        case Charge.ChargeFrequency.HALF_YEARLY: return 6
        case Charge.ChargeFrequency.ANNUALLY: return 12
        default: throw new Error('bad frequency')
    }
}