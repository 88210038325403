import { validators, type VArgs } from '@tolemac/web-components'

const {
    externalWithI18nMessage,
    required,
    betweenDate,
    maxDate,
    maxLength,
    minValue,
} = validators

import { IInvoice } from '@tolemac/grpc_web_api/tolemac/pub/model';

type InvoiceValFacOpt = { dateIsEditable?: boolean; lastInvoice?: number | null; today: number }

export class InvoiceValFac {

    init(opt: InvoiceValFacOpt) {
        return {
            status: {},
            addressLine1: { maxLength38: maxLength(38) },
            addressLine2: { maxLength38: maxLength(38) },
            postalCode: {},
            city: {},
            state: {},
            country: {},
            paymentDeadline: {},
            paymentDeadlineEndMonth: {},
            date: this.date(opt),

            paymentCondition: {},
            mandatoryInformation: {},
            comment: {},
            company: { required },
            projects: {
                $isArray: true,
                length: { min: minValue(1, 'validations.invoice.projects') },

                maxAmount: externalWithI18nMessage('validations.invoice.max_amount'),

                id: {},
                index: {},
                project: {
                    id: {}
                },
                items: {
                    $isArray: true,
                    length: { minValue: minValue(1, "validations.invoice.items") },
                    index: {},
                    id: {},
                    name: {},
                    description: {},
                    discountType: {},
                    discount: {},
                    value: {},
                    quantity: {}
                }
            }
        } satisfies VArgs<IInvoice>
    }

    update(ref: ReturnType<(typeof this)['init']>, opt: InvoiceValFacOpt) {
        ref.date = this.date(opt)
    }

    private date(opt?: InvoiceValFacOpt) {
        if (!opt) return {}
        return !opt.dateIsEditable ? {} :
            opt.lastInvoice ?
                { between: betweenDate(opt.lastInvoice, opt.today, { min_label: "la date de dernière facture", min_date: opt.lastInvoice, max_label: "la date du jour", max_date: opt.today }) }
                :
                { max: maxDate(opt.today, { date_label: "la date du jour", max_date: opt.today }) }
    }
}