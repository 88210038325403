<template>
    <div class="m-auto w-full bg-white rounded-md" :style="{ minHeight: minHeightModal }">
        <div class="h-full w-full flex flex-col items-center relative bg-gray-50">
            <div class="w-full grid grid-cols-3 py-2">
                <span class="col-start-2 text-center">
                    <InvoiceStatus :status="status" />
                </span>
                <span class="flex justify-between items-center">
                    <!-- <t-switch v-model="pdf" size="s" /> -->
                    <t-icon-x-mark class="ml-auto mr-2 my-1 w-5 p-0.5 border rounded-lg cursor-pointer bg-white"
                        @click="dismiss()" />
                </span>
            </div>

            <div class="flex-grow w-full max-w-screen overflow-y-hidden rounded-b-md bg-gray-50"
                :class="{ 'mb-16': !noAction }" ref="iframeContainer">
                <iframe
                    :src="pdf ? `/api/invoice/pdf/id/${$props.invoiceId}` : `/api/invoice/preview/id/${$props.invoiceId}`"
                    @load="onIframeLoad" class="bg-white mx-auto overflow-x-hidden px-4"
                    :style="{ width: '21cm', height: `${((1 / scaleIframe) * 100).toFixed(0)}%`, transform: `scale(${scaleIframe})`, transformOrigin: 'top left' }" />
            </div>

            <div v-if="!noAction"
                class="absolute w-full px-4 bottom-0 py-2 bg-gray-50 rounded-md flex flex-wrap justify-between gap-x-2">

                <t-button v-if="actions.deliverWithDate" @click="actions.deliverWithDate">
                    {{ $t('page.invoice.actions.deliver') }}
                </t-button>

                <t-button v-if="actions.deliver"
                    v-vuelidate="() => ({ click: actions.deliver!, validation: invoice$, unchanged: true })">
                    {{ $t('page.invoice.actions.deliver') }}
                </t-button>

                <t-button v-if="actions.pay"
                    v-vuelidate="() => ({ click: actions.pay!, validation: invoice$, unchanged: true })">
                    {{ $t('page.invoice.actions.pay') }}
                </t-button>

                <t-button v-if="actions.modify" color="secondary" @click="edit">
                    {{ $t('actions.modify') }}
                </t-button>

                <div class="grow text-right">
                    <!-- :download="`${invoice.ref || $props.invoiceId}.pdf`" -->
                    <t-a-href :href="`/api/invoice/pdf/id/${$props.invoiceId}`" :title="$t('actions.download')"
                        class="t-fake-button" target="_self">
                        <ArrowDownTrayIcon class="w-5 text-ocean" />
                        <span class="pl-2">{{ $t('actions.download') }}</span>
                    </t-a-href>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, onMounted, onUnmounted, watch, PropType } from 'vue'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'

import { Invoice, Project } from '@tolemac/grpc_web_api/tolemac/pub/model';

import plugins from '@/plugins';
import { debounce } from '@/composition/debounce';

import InvoiceStatus from '@/components/invoice/InvoiceStatus.vue'

type Actions = {
    deliver?: () => void;
    deliverWithDate?: () => void;
    pay?: () => void;
    modify?: boolean;
}

export default defineComponent({
    name: 't-invoice-modal',
    components: {
        InvoiceStatus,
        ArrowDownTrayIcon
    },
    props: {
        invoiceId: { type: Number, required: true },
        /** load invoice if not defined  */
        invoiceStatus: Number as PropType<Invoice.InvoiceStatus>,
        /** hide all actions button */
        noAction: Boolean
    },
    async setup(props) {
        const { $api, $modal, $router, util: { invoice: { useInvoiceLoader, useInvoiceActions } } } = plugins;

        const { loadInvoice, invoice, invoice$, lastInvoiceDate } = useInvoiceLoader(['@company', 'projects.stats'])
        const status = ref(props.invoiceStatus)
        const actionsEnabled = ref<Actions>({})
        const actions = useInvoiceActions(
            invoice,
            {
                onDeliver() {
                    status.value = Invoice.InvoiceStatus.DELIVERED
                    $modal.setOnDismiss(() => true)
                },
                onPay() {
                    status.value = Invoice.InvoiceStatus.PAID
                    $modal.setOnDismiss(() => true)
                }
            }
        )
        const { iframeContainer, scaleIframe, minHeightModal, onIframeLoad } = useIframeLoader()

        async function asyncLoad(_invoiceId: number, _invoiceStatus?: Invoice.InvoiceStatus, _noAction = false) {
            let _invoice: Invoice | undefined = undefined
            if (!_invoiceStatus) {
                _invoice = await loadInvoice(_invoiceId)
                _invoiceStatus = _invoice.status!
            }

            const result: Actions & { status: Invoice.InvoiceStatus } = { status: _invoiceStatus }

            if (_noAction) return result

            if (_invoiceStatus === Invoice.InvoiceStatus.DRAFT) {
                if (!_invoice) _invoice = await loadInvoice(_invoiceId)

                if (!invoice$.value.$validate()) {
                    const fieldErrors = invoice$.value.$silentErrors.map(r => r.$propertyPath)
                    if (fieldErrors.length === 1 && fieldErrors[0] === 'date')
                        result.deliverWithDate = () => actions.deliverWithDate(lastInvoiceDate.value!)
                }

                if (!result.deliverWithDate)
                    result.deliver = actions.deliver

            } else if (_invoiceStatus === Invoice.InvoiceStatus.DELIVERED || _invoiceStatus === Invoice.InvoiceStatus.DELIVERED__LATE) {
                const [_projects] = await Promise.all([
                    $api.project.find({
                        criterias: [{ field: 'invoicesProjectStats.invoiceId', value: `${_invoiceId}` }],
                        relations: ['stats']
                    }).then(p => p.values),
                    _invoice ?? loadInvoice(_invoiceId).then(i => _invoice = i)
                ])

                // is "all projects to archive" / "some projects to archive" / "no projects to archive" ?
                const archiveProject: string[] = []
                for (const project of _projects) {
                    const invoiceProject = _invoice!.projects!.find(ip => ip.project!.id === project.id)
                    if (!invoiceProject?.project?.status || invoiceProject.project.status < Project.ProjectStatus.DELIVERED)
                        continue

                    const invoiceProjectAmount = invoiceProject?.stats?.amount || 0
                    const projectAmount = project.stats?.amount || 0
                    const projectAmountPaid = project.stats?.amountPaid || 0

                    if (projectAmount.toFixed(2) === (projectAmountPaid + invoiceProjectAmount).toFixed(2))
                        archiveProject.push(project.name!)
                }

                if (archiveProject.length === _projects.length)
                    result.pay = () => actions.pay({ archiveProject: true })
                else if (archiveProject.length === 0)
                    result.pay = actions.pay
                else
                    result.pay = () => actions.pay({ archiveProject })
            }

            if (_invoiceStatus >= Invoice.InvoiceStatus.DRAFT && _invoiceStatus < Invoice.InvoiceStatus.PAID)
                result.modify = true

            return result
        }

        const _onIframeLoad = debounce(() => onIframeLoad())
        onMounted(() => window.addEventListener("resize", _onIframeLoad))
        onUnmounted(() => window.removeEventListener("resize", _onIframeLoad))

        watch(
            status,
            async s => {
                const { status: _status, ..._actions } = await asyncLoad(props.invoiceId, s, props.noAction)
                actionsEnabled.value = _actions
                status.value = _status
            },
            { immediate: true }
        )

        return {
            iframeContainer,
            scaleIframe,
            minHeightModal,
            onIframeLoad,

            pdf: ref(false),

            status,
            invoice,
            invoice$,

            actions: actionsEnabled,
            dismiss: $modal.dismiss,
            async edit() {
                await $router.push2(
                    { name: 'invoice', params: { id: props.invoiceId } },
                    { unlock: true }
                )
            }
        }
    }
})

function useIframeLoader() {
    const iframeContainer = ref<HTMLDivElement>()
    const scaleIframe = ref(1)
    const minHeightModal = ref('100%')

    let retry = 0
    function onIframeLoad(onLoadEvent?: any) {
        retry += 1

        let iframe = onLoadEvent?.target as HTMLIFrameElement
        if (!iframe)
            iframe = iframeContainer.value?.getElementsByTagName('iframe')[0] as HTMLIFrameElement
        if (!iframe && retry < 10) {
            setTimeout(() => onIframeLoad(onLoadEvent), 100);
            return
        }

        const iframeHtml = iframe.contentDocument?.querySelector('html') as HTMLHtmlElement
        if (!iframeHtml?.scrollHeight && retry < 10) {
            setTimeout(() => onIframeLoad(onLoadEvent), 100);
            return
        }
        retry = 0
        const iframeScrollHeight = iframeHtml.scrollHeight

        nextTick(() => {
            if (!iframeContainer.value || !iframeContainer.value.scrollWidth || !iframeContainer.value.offsetWidth)
                scaleIframe.value = 1
            else
                scaleIframe.value = Math.min(iframeContainer.value.offsetWidth / iframeContainer.value.scrollWidth, 1)

            if (iframeScrollHeight && window.innerHeight > iframeScrollHeight)
                minHeightModal.value = `${iframeScrollHeight}px`

            if (scaleIframe.value === 0 && retry < 10)
                setTimeout(() => onIframeLoad(onLoadEvent), 100);
        })
    }

    return {
        iframeContainer,
        scaleIframe,
        minHeightModal,

        onIframeLoad,
    }
}

</script>