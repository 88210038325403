import {
    ICharge,
    ICompanyLabel,
    IContact,
    IContactLabel,
    IExchange,
    IOrga,
    IProject,
    IProjectLabel,
    ITaxRate,
    IUser,
    //
    Service,
    Charge,
    IProjectService,
} from "@tolemac/grpc_web_api/tolemac/pub/model"

import { validators as Validators, type VArgs } from '@tolemac/web-components';
const {
    withI18nMessage,
    externalWithI18nMessage,
    required,
    maxLength,
    minLength,
    email,
    phone,
    url,
    regexp
} = Validators

import { InvoiceValFac } from "./util.validators.invoice";
import { CompanyValFac } from "./util.validators.company";
import { ServiceValFac } from "./util.validators.service";

type Label = ICompanyLabel | IContactLabel | IProjectLabel


// get partial validator:
// (
//     ({ firstname, lastname }) =>
//     ({ firstname, lastname })
// )(validators.user)

export const validators = {

    // // // // // // // used only in component

    get user() {
        return {
            email: { required, email },
            firstname: { required },
            lastname: { required },
            mobilephone: { phone },
            job: {},
            picture: {} // fileMaxSize1Mo, fileIsPicture }
        } satisfies VArgs<IUser>
    },

    get orga() {
        return {
            name: {},
            legalForm: {},
            email: { email },
            phone: { phone },
            website: { url },
            addressLine1: {},
            addressLine2: {},
            postalCode: {},
            city: {},
            country: {},
            state: {},
            langs: { $isArray: true },
            quoteParam: {
                deadline: { required }
            },
            invoiceParam: {
                deadline: {},
                deadlineEndMonth: {},
                legalNotice: {},
                paymentCondition: {},
                extraLine1: {},
                extraLine2: {},
                extraLine3: {}
            }
        } satisfies VArgs<IOrga>
    },

    get signupPassword() {
        return {
            required,
            minLength8: minLength(8),
            maxLength28: maxLength(28)
        } satisfies VArgs<string>
    },

    get changePassword() {
        return {
            required,
            minLength8: minLength(8),
            maxLength28: maxLength(28),
            digit: regexp(/[0-9]/, 'validations.contain_digit'),
            uppercase: regexp(/[A-Z]/, 'validations.contain_uppercase'),
            lowercase: regexp(/[a-z]/, 'validations.contain_lowercase')
        } satisfies VArgs<string>
    },

    // // // // // // // used only in util

    get charges() {

        function totalChargesValue(charges: ICharge[], date: number, filter?: (v: ICharge) => (boolean | undefined)) {
            if (filter)
                charges = charges.filter(filter)
            return charges
                // get ChargeValue at this date, if exist 
                .filter(t => t.values && t.values.length)
                .map(t => t.values!.find(tValue => (
                    tValue.beginDate && tValue.beginDate <= date && (!tValue.endDate || tValue.endDate >= date)
                )))
                .map(tValue => tValue?.value || 0)
                // sum
                .reduce((tot, cur) => tot += cur, 0);
        }

        const max100 = withI18nMessage<any, ArrayType<ICharge['values']>, ICharge[]>((_, { parent: chargeValue, root: charges, getParent }) => {
            const charge = getParent<ICharge>(3)!

            if (charge.type !== Charge.ChargeType.VARIABLE || !chargeValue || !chargeValue.value) return true;
            if (!chargeValue.beginDate || chargeValue.value > 100) return false

            function isIn(date: number) {
                return date >= chargeValue!.beginDate! && (!chargeValue!.endDate || date <= chargeValue!.endDate)
            }

            const testedDate: number[] = []
            function isGreater100(date: number) {
                if (testedDate.indexOf(date) === -1) {
                    if (isIn(date)) {
                        const total = totalChargesValue(charges, date, t => t.type === Charge.ChargeType.VARIABLE)
                        // console.log(path, new Date(date).toLocaleDateString(), total)
                        if (total > 100) return true
                    }
                    testedDate.push(date)
                }
            }

            if (isGreater100(chargeValue.beginDate))
                return false
            if (chargeValue.endDate && isGreater100(chargeValue.endDate))
                return false

            for (const t of charges.filter(t => !charge.id || t.id != charge.id))
                if (t.values)
                    for (const v of t.values) {
                        if (!v.beginDate || isGreater100(v.beginDate))
                            return false
                        if (v.endDate && isGreater100(v.endDate))
                            return false
                    }

            return true
        }, 'validations.charges.max_value')

        return {
            $isArray: true,
            id: {},
            label: {
                required,
                uniq: withI18nMessage(
                    (_label, { root: _charges }) => {
                        let count = 0
                        for (const _charge of _charges) {
                            if (!_charge.label) continue
                            if (_charge.label === _label) count++
                            if (count > 1) return false
                        }
                        return true
                    },
                    { i18nKey: 'validations.charges.label_uniq', jsonQueries: ['*.label'] }
                )
            },
            frequency: {
                required: withI18nMessage((value: any) => !!value, "validations.required")
            },
            values: {
                $isArray: true,
                value: { required, max100 },
                beginDate: { max100, required },
                endDate: { max100 }
            }
        } satisfies VArgs<ICharge[]>
    },

    // with external
    invoice: new InvoiceValFac(),

    get tags() {
        return {
            $isArray: true,
            id: {},
            label: {
                required,
                maxLength38: maxLength(28),
                uniq: withI18nMessage(
                    (_label, { root: _tags }) => {
                        let count = 0
                        for (const _tag of _tags) {
                            if (!_tag.label) continue
                            if (_tag.label === _label) count++
                            if (count > 1) return false
                        }
                        return true
                    },
                    { i18nKey: 'validations.tags.label_uniq', jsonQueries: ['*.label'] }
                )
            },
            description: {},
            color: {}
        } satisfies VArgs<Label[]>
    },

    get taxesRate() {

        const uniq = withI18nMessage<string | number | undefined, ITaxRate, ITaxRate[]>(
            (a, { root, parent }) =>
                root.filter(_t => parent?.name === _t.name && parent?.value === _t.value).length <= 1
            ,
            { i18nKey: 'errors.BAD_REQUEST_UNICITY__UQ_TAX_NAME', jsonQueries: ['*.name.uniq', '*.value.uniq'] }
        )

        return {
            $isArray: true,
            id: {},
            name: { required, uniq },
            value: { required, uniq },
            default: {
                requiredDefault: withI18nMessage(
                    (a, { root }) => root.filter(_t => _t.default).length === 1,
                    { i18nKey: 'validations.required', jsonQueries: ['*.default'] }
                )
            }
        } satisfies VArgs<ITaxRate[]>
    },

    // // // // // // // used in util and compoenent

    service: new ServiceValFac(),

    company: new CompanyValFac(),

    get contact() {
        return {
            contactVArgs: {
                firstname: { required },
                lastname: { required },
                email: { email },
                phone: { phone },
                job: {},
                labels: {
                    $isArray: true,
                    id: {}
                },
                company: {
                    id: {}
                }
            } satisfies VArgs<IContact>
        }
    },

    get exchange() {
        return {
            exchangeVArgs: {
                title: {},
                description: { required },
                type: { required },
                date: { required },
                project: { id: {} },
                contact: { id: {} },
                company: { id: {} }
            } satisfies VArgs<IExchange>
        }
    },

    // with external
    get project() {

        return {
            name: { required },
            informations: {},
            status: {},

            creationDate: {
                required,
                // RG : creationDate <= expiredDate
                max: withI18nMessage((_creationDate, { parent: _project }) => {
                    return !_project || !_creationDate || !_project.expiredDate || _creationDate <= _project.expiredDate
                }, { i18nKey: 'validations.max_date', i18nParams: { date_label: "validité de l'offre" } })

            },
            expiredDate: {
                required,
                // RG : expiredDate >= creationDate
                min: withI18nMessage((_expiredDate, { parent: _project }) =>
                    !_project || !_expiredDate || !_project.creationDate || _expiredDate >= _project.creationDate, { i18nKey: 'validations.min_date', i18nParams: { date_label: "la date de l'offre" } })
            },
            estimatedWorkTime: {},
            workTime: {},
            startingDate: {
                // RG : startingDate >= creationDate
                min: withI18nMessage((_startingDate, { parent: _project }) =>
                    !_project || !_startingDate || !_project.creationDate || _startingDate >= _project.creationDate, { i18nKey: 'validations.min_date', i18nParams: { date_label: "la date de l'offre" } })
            },
            dueDate: {
                required,
                // RG : dueDate >= creationDate
                min: withI18nMessage((_dueDate, { parent: _project }) =>
                    !_project || !_dueDate || !_project.startingDate || _dueDate >= _project.startingDate, { i18nKey: 'validations.min_date', i18nParams: { date_label: "la date du début du projet" } })
            },
            deliveryDate: {
                // RG : deliveryDate >= creationDate
                min: withI18nMessage((_dueDate, { parent: _project }) =>
                    !_project || !_dueDate || !_project.startingDate || _dueDate >= _project.startingDate, { i18nKey: 'validations.min_date', i18nParams: { date_label: "la date du début du projet" } })
            },
            delivery: {},
            orderNumber: {},

            refContact: { id: {} },
            businessArea: {},

            taxRateName: {},
            taxRateValue: { required },

            paiementScheduler: {
                $isArray: true
            },

            labels: {
                $isArray: true
            },

            minAmount: externalWithI18nMessage('validations.projet.min_amount'),

            services: {
                $isArray: true,

                name: {},
                description: {},

                quantity: {
                    required: withI18nMessage(
                        (value, { parent: service }) => service?.unit === Service.ServiceUnit.PRICE_GRID || !!value || !!service?.discount,
                        'validations.required'
                    )
                },
                value: {
                    required: withI18nMessage(
                        (value, { parent: service }) => service?.unit === Service.ServiceUnit.PRICE_GRID || value !== undefined,
                        'validations.required'
                    )
                },
                valuePriceGrid: {
                    id: {},
                    basePrice: {
                        required: withI18nMessage(
                            (value, { getParent }) => getParent<IProjectService>(2)?.unit !== Service.ServiceUnit.PRICE_GRID || value !== undefined,
                            'validations.required'
                        )
                    },
                    vPerfectMatch: {},
                    vContextMatch: {},
                    vRepeats: {},
                    vRepeatsBtwFiles: {},
                    v100: {},
                    v95v99: {},
                    v85v94: {},
                    v75v84: {},
                    v0v74: {},
                    vRefAdaptativeMT: {},
                    vAdaptativeMTWithLearning: {},
                    vNewWords: {},
                    qvPerfectMatch: {},
                    qvContextMatch: {},
                    qvRepeats: {},
                    qvRepeatsBtwFiles: {},
                    qv100: {},
                    qv95v99: {},
                    qv85v94: {},
                    qv75v84: {},
                    qv0v74: {},
                    qvRefAdaptativeMT: {},
                    qvAdaptativeMTWithLearning: {},
                    qvNewWords: {}
                },
                discount: {},
                discountType: {}
            }
        } satisfies VArgs<IProject>

    }
}

