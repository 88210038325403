import { validators, type VArgs } from '@tolemac/web-components'

const { required, withI18nMessage } = validators

import { IPriceGrid, IService, Service } from '@tolemac/grpc_web_api/tolemac/pub/model';

type ServiceValFacOpt = {
    unit: Service.ServiceUnit;
    isInvoice?: boolean
}

export class ServiceValFac {

    init(opt: ServiceValFacOpt) {

        const langRequired = (opt.unit === Service.ServiceUnit.WORD || opt.unit === Service.ServiceUnit.PRICE_GRID)

        const priceGridRequired = opt.unit === Service.ServiceUnit.PRICE_GRID && !opt.isInvoice

        return {
            name: { required },
            description: {},
            unit: {},
            langSrc: langRequired ? { required } : {},
            langTarget: langRequired ? { required } : {},
            value: priceGridRequired ? {} : { required },
            valuePriceGrid: priceGridRequired ? this.priceGrid() : undefined,
            company: {
                id: {}
            }
        } satisfies VArgs<IService>
    }

    priceGrid() {
        const maxValue = () => withI18nMessage<number, IPriceGrid>((value, { parent }) => (!value || !!parent!.basePrice && value <= parent!.basePrice), { i18nKey: 'validations.max_value', i18nParams: { max: 100 } })

        return {
            required,
            id: {},
            basePrice: { required },
            vPerfectMatch: { maxValue100: maxValue() },
            vContextMatch: { maxValue100: maxValue() },
            vRepeats: { maxValue100: maxValue() },
            vRepeatsBtwFiles: { maxValue100: maxValue() },
            v100: { maxValue100: maxValue() },
            v95v99: { maxValue100: maxValue() },
            v85v94: { maxValue100: maxValue() },
            v75v84: { maxValue100: maxValue() },
            v0v74: { maxValue100: maxValue() },
            vRefAdaptativeMT: { maxValue100: maxValue() },
            vAdaptativeMTWithLearning: { maxValue100: maxValue() },
            vNewWords: { maxValue100: maxValue() },
        } satisfies VArgs<IPriceGrid>
    }

    update(ref: ReturnType<(typeof this)['init']>, opt: ServiceValFacOpt) {
        const langRequired = (opt.unit === Service.ServiceUnit.WORD || opt.unit === Service.ServiceUnit.PRICE_GRID)
        if (langRequired) {
            if (!ref.langSrc.required) ref.langSrc = { required }
            if (!ref.langTarget.required) ref.langTarget = { required }
        } else {
            if (ref.langSrc.required) ref.langSrc = {}
            if (ref.langTarget.required) ref.langTarget = {}
        }

        const priceGridRequired = opt.unit === Service.ServiceUnit.PRICE_GRID && !opt.isInvoice
        if (priceGridRequired && !ref.valuePriceGrid) {
            ref.valuePriceGrid = this.priceGrid()
            ref.value = {}
        } else if (!priceGridRequired && ref.valuePriceGrid) {
            ref.valuePriceGrid = undefined
            ref.value = { required }
        }
    }
}