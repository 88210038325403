import { validators, type VArgs } from '@tolemac/web-components'

const {
    required,
    maxLength,
    email,
    url,
    phone
} = validators

import { ICompany } from '@tolemac/grpc_web_api/tolemac/pub/model';

type CompanyValFacOpt = { isIndividual: boolean }

export class CompanyValFac {

    init(opt: CompanyValFacOpt) {
        const maxLength38 = maxLength(38);
        const maxLength10 = maxLength(10);

        return {
            firstname: opt.isIndividual ? { required } : undefined,
            lastname: opt.isIndividual ? { required } : undefined,
            name: opt.isIndividual ? undefined : { required },
            email: { email },
            addressLine1: { maxLength38 },
            addressLine2: { maxLength38 },
            postalCode: { maxLength10 },
            city: {},
            country: {},
            state: {},
            phone: { phone },
            legalForm: {},
            paymentDeadline: {},
            paymentDeadlineEndMonth: {},
            website: { url },
            prospect: {},
            activitySector: {},
            taxRate: {
                id: {}
            },
            labels: {
                $isArray: true,
                id: {}
            }
        } satisfies VArgs<ICompany>
    }

    update(ref: ReturnType<(typeof this)['init']>, opt: CompanyValFacOpt) {
        ref.firstname = opt.isIndividual ? { required } : undefined
        ref.lastname = opt.isIndividual ? { required } : undefined
        ref.name = opt.isIndividual ? undefined : { required }
    }
}