import { ITaxRate, ITaxesRate } from "@tolemac/grpc_web_api/tolemac/pub/model";

import { syncLazyResources, useValidation } from "@tolemac/web-components";
import { validators } from './util.validators'

import TaxesModal from '@/components/taxes/TaxesModal.vue'

import { modalController } from '../modal'
import { useApi } from "../api";

export const taxes = {

    openModal(): Promise<ITaxRate | undefined> {
        return modalController.openCustom({
            component: TaxesModal
        })
    },

    useTaxes(load?: (() => Promise<ITaxesRate>), onLoad?: (() => void)) {

        const $api = useApi()

        if (!load)
            load = () => $api.taxRate.find({})

        const validTaxeRate = useValidation(
            [] as ITaxRate[],
            validators.taxesRate
        )

        const { sync: taxesRate$sync } = syncLazyResources(
            $api.taxRate.add.bind($api.taxRate),
            $api.taxRate.update.bind($api.taxRate),
            $api.taxRate.delete.bind($api.taxRate),
            validTaxeRate,
            load,
            onLoad
        )

        return {
            taxesRate: validTaxeRate.refObj,
            taxesRate$: validTaxeRate.validationObj,
            taxesRate$set: validTaxeRate.setVal,
            taxesRate$reset: validTaxeRate.resetVal,
            taxesRate$sync
        }

    }
}