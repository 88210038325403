<template>
  <div class="t-modal">
    <div class="px-2 md:px-5">
      <div class="flex items-center pb-5">
        <div class="inline-block" v-if="add !== true">
          <ServiceLogo :unit="serv.unit" class="w-6 h-6" />
        </div>
        <div class="inline-block" v-else>
          <ReceiptPercentIcon class="w-6 h-6" />
        </div>
        <div class="inline-block pl-4">
          <h2>
            <template v-if="isCatalog()">
              {{ $props.add ? $t('section.service.catalog.title_add') : $t('section.service.catalog.title_edit') }}
            </template>
            <template v-else-if="isProject()">
              {{ $props.add ? $t('section.service.catalog.title_add') : $t('section.service.project.title_edit') }}
            </template>
            <template v-else>
              {{ $props.add ? $t('section.service.invoice.title_add') : $t('section.service.invoice.title_edit') }}
            </template>
          </h2>

          <p class="text-sm text-gray-500">
            <template v-if="isCatalog()">
              {{ $props.add ?
          $t('section.service.catalog.description_add') :
          $t('section.service.catalog.description_edit')
              }}
            </template>
            <template v-else-if="isProject()">
              {{ $props.add ?
          $t('section.service.catalog.description_add') :
          $t('section.service.project.description_edit')
              }}
            </template>
            <template v-else-if="isInvoice()">
              {{ $props.add ?
          $t('section.service.invoice.description_add') :
          $t('section.service.invoice.description_edit')
              }}
            </template>
          </p>
        </div>
      </div>

      <form action="" class="grid grid-cols-12 gap-4">

        <div class="col-span-12" v-if="add === true">
          <div class="flex flex-wrap justify-between px-8">
            <div v-for="s in units" :key="s"
              class="cursor-pointer flex flex-wrap item-center justify-center align-center gap-x-2"
              @click="serv.unit = s">
              <ServiceLogo :unit="s" class="w-6" :disabled="serv.unit !== s"
                :with-title="$store.display.breakpoint.mdAndUp" />
            </div>
          </div>
        </div>

        <div class="col-span-12 flex items-center gap-x-6" v-if="isCatalog() || isProject()">
          <div>{{ $t('model.service.ref') }}:</div>
          <div class="text-sm font-bold text-ocean">
            <span v-if="serv?.ref === null">
              <t-icon-arrow-path class="w-5 animate-spin" />
            </span>
            <span v-else-if="serv?.ref">{{ serv.ref }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <t-input v-model="serv.name" :validation="serv$.name" type="text" :placeholder="$t('model.service.name')"
            autofocus />
        </div>

        <ComboboxCompany v-if="isCatalog(serv)" v-model="serv.company" :validation="serv$.company"
          :placeholder="$t('model.service.company')" nullable class="col-span-12 sm:col-span-6" />

        <div class="col-span-12" v-if="$props.add || isCatalog() || !$store.display.breakpoint.lgAndUp">
          <t-input v-model="serv.description" :validation="serv$.description" type="text"
            :placeholder="$t('model.service.description')" multiline rows="6" />
        </div>

        <template v-if="selectLangDisabled">
          <div class="col-start-1 col-end-10 sm:col-start-1 sm:col-end-7">
            <LangSelect v-model="serv.langSrc" :placeholder="$t('model.service.langSrc')" disabled />
          </div>
          <div class="col-start-1 col-end-10 sm:col-start-7 sm:col-end-13">
            <LangSelect v-model="serv.langTarget" :placeholder="$t('model.service.langTarget')" disabled />
          </div>
        </template>
        <template v-else-if="selectLangRequired">
          <div
            :class="(serv.langTarget && serv.langSrc) ? 'col-start-1 col-end-10 sm:col-start-1 sm:col-end-6' : 'col-start-1 col-end-10 sm:col-start-1 sm:col-end-7'">
            <LangSelect v-model="serv.langSrc" :validation="serv$.langSrc" :other-lang="serv.langTarget"
              :placeholder="$t('model.service.langSrc')" />
          </div>
          <Transition name="transition-opacity">
            <div class="row-span-2 col-start-10 col-end-13 sm:row-span-1 sm:col-start-6 sm:col-end-8 m-auto"
              v-if="serv.langTarget && serv.langSrc">
              <t-button @click="switchLangs" color="secondary">
                <ArrowsRightLeftIcon class="w-4" />
              </t-button>
            </div>
          </Transition>
          <div
            :class="(serv.langTarget && serv.langSrc) ?  ($store.display.breakpoint.sm  ? 'col-start-1 col-end-10' : 'col-start-8 col-end-13') : 'col-start-1 col-end-10 sm:col-start-7 sm:col-end-13'">
            <LangSelect v-model="serv.langTarget" :validation="serv$.langTarget" :other-lang="serv.langSrc"
              :placeholder="$t('model.service.langTarget')" />
          </div>
        </template>

        <div class="col-start-1 col-end-13 sm:col-end-6">
          <t-input v-if="serv.valuePriceGrid" v-model.float="serv.valuePriceGrid.basePrice"
            :validation="serv$.valuePriceGrid?.basePrice" :placeholder="$t('model.service.value')" float-precision="3"
            min="0" :end="$formatter.currency + $enum.ServiceUnitBy.translate(serv.unit, { prefix: '/' })" />
          <t-input v-else-if="isInvoice() && serv.unit === $enum.ServiceUnit.PRICE_GRID" v-model.float="serv.value"
            :validation="serv$.value" :placeholder="$t('model.service.value')" float-precision="2" min="0"
            :end="$formatter.currency" />
          <t-input v-else v-model.float="serv.value" :validation="serv$.value" :placeholder="$t('model.service.value')"
            float-precision="2" min="0"
            :end="$formatter.currency + $enum.ServiceUnitBy.translate(serv.unit, { prefix: '/' })" />
        </div>

        <template v-if="serv.valuePriceGrid">
          <hr class="col-start-3 col-end-11" />
          <div class="col-start-1 col-end-13 uppercase pb-4">
            {{ $t('section.service.pricegrid') }}
          </div>

          <div class="col-span-12 xl:col-span-10 t-2n-bg-gray-50">
            <div v-for="att in $util.service.PRICE_GRID_ATTRS" :key="att"
              class="w-full grid grid-cols-9 gap-1 rounded-lg">

              <div class="col-span-4 md:col-span-5 my-auto pl-1">{{ $t('model.price_grid.' + att) }}</div>

              <t-input class="col-span-3 md:col-span-2" input-class="py-0" v-model.integer="percents[att]"
                :validation="(serv$.valuePriceGrid as any)[att]" max="100" min="0" end="%" />

              <div class="col-span-2 my-auto text-right pr-1">
                {{ $formatter.price(serv.valuePriceGrid![att] || 0, { precision: 6, addZero: false }) }}
              </div>

            </div>
          </div>
        </template>

      </form>
    </div>
    <div class="flex flex-row flex-grow-0  gap-2 px-5 pt-5">
      <t-button v-vuelidate="() => ({ click: save, validation: serv$, unchanged: serv?.id === undefined })">
        {{ $t('actions.save') }}
      </t-button>
      <t-button color="white" @click="forceDismiss()">
        {{ $t('actions.cancel') }}
      </t-button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, nextTick, PropType, Ref } from 'vue'
import { ArrowsRightLeftIcon, ReceiptPercentIcon } from '@heroicons/vue/24/outline'

import { PriceGrid, Service, IService, IInvoiceItem, IProjectService } from '@tolemac/grpc_web_api/tolemac/pub/model'

import { useValidation } from '@tolemac/web-components'

import plugins from '@/plugins'
import { PRICE_GRID_ATTRS, PriceGridValues } from '@/plugins/util/util.service';

import ServiceLogo from '@/components/services/ServiceLogo.vue'
import LangSelect from '@/components/lang/LangSelect.vue'
import ComboboxCompany from '@/components/company/ComboboxCompany.vue'

/** ref null means => calling api */
type RefNull<T extends { ref?: string }> = Omit<T, 'ref'> & { ref?: string | null }
type _InvoiceItem = IInvoiceItem & { valuePriceGrid: undefined }
type _Service = RefNull<IService> | RefNull<IProjectService> | RefNull<_InvoiceItem>

type ModalType = 'catalog' | 'project' | 'invoice'

type PriceGridPercent = { [key in PriceGridValues]: number; }

export default defineComponent({
  components: {
    LangSelect, ServiceLogo, ComboboxCompany,
    ArrowsRightLeftIcon, ReceiptPercentIcon
  },
  props: {
    service: Object as PropType<_Service>,
    companyId: Number,

    add: { type: [Boolean, String] as PropType<boolean | 'copy'>, required: true },

    type: { type: String as PropType<ModalType>, default: () => 'catalog' }

  },
  async setup(props) {
    const { $api, $store, $modal, $languages, $enum, util: { validators: { service: serviceValidator } } } = plugins

    const isCatalog = (s?: _Service): s is IService => props.type === 'catalog'
    const isProject = (s?: _Service): s is IProjectService => props.type === 'project'
    const isInvoice = (s?: _Service): s is _InvoiceItem => props.type === 'invoice'

    const myLanguages = computed(() => $languages.getAll($store.account.$state.user?.orga?.langs))

    const initUnit = props.service?.unit || Service.ServiceUnit.WORD
    const percents = ref<null | PriceGridPercent>(null)
    const serv$args = ref(serviceValidator.init({ unit: initUnit, isInvoice: isInvoice() }))
    const serv = ref<null | _Service>(null)
    const { validationObj: serv$, setVal: serv$set } = useValidation(serv, serv$args)

    // init
    if (isCatalog()) {
      $modal.addLock(serv$.value.$uid)
      if (props.add && props.service)
        serv$set(duplicateService(props.service))
    }

    if (!isCatalog() || !serv.value)
      serv$set(props.service ?
        JSON.parse(JSON.stringify(props.service)) :
        { unit: initUnit, valuePriceGrid: {} }
      )

    if (serv.value?.valuePriceGrid)
      percents.value = priceGridToPercents(serv.value.valuePriceGrid)

    // update percents
    watch(percents, v => {
      if (serv.value && v)
        serv.value.valuePriceGrid = percentsToPriceGrid(serv.value.valuePriceGrid?.basePrice, v)
    }, { deep: true })
    watch(() => serv.value?.valuePriceGrid?.basePrice, b => {
      if (serv.value && percents.value)
        serv.value.valuePriceGrid = percentsToPriceGrid(b, percents.value)
    })

    watch(() => serv.value?.unit, unit => {
      // update validation langSrc + langTarget required on PRICE_GRID or WORD
      if (unit)
        serviceValidator.update(serv$args.value, { unit, isInvoice: isInvoice() })

      if (serv.value)
        if (!isInvoice(serv.value) && unit === $enum.ServiceUnit.PRICE_GRID) {
          if (!serv.value.valuePriceGrid)
            serv.value.valuePriceGrid = {}
        } else {
          serv.value.valuePriceGrid = undefined
        }
    }, { immediate: true })

    // update ref on new service
    if (props.add && !isInvoice()) {
      watch([() => serv.value?.unit, () => serv.value?.langSrc, () => serv.value?.langTarget], async ([unit, langSrc, langTarget]) => {
        if (!serv.value) return

        if (
          !unit
          ||
          ((unit === $enum.ServiceUnit.PRICE_GRID || unit === $enum.ServiceUnit.WORD) && (!langSrc || !langTarget))
        ) {
          serv.value.ref = undefined
          return
        }

        serv.value.ref = null
        const [newRef] = await Promise.all([
          $api.service.generateref({
            unit: unit as Service.ServiceUnit,
            langSrc: langSrc as string | undefined,
            langTarget: langTarget as string | undefined,
          }),
          new Promise(r => setTimeout(r, 200))
        ])
        serv.value.ref = newRef.value

      }, { immediate: true })
    }

    // auto select lang
    if (props.add) {
      const unwatchLang: (() => void) = watch(() => [serv.value?.langSrc, serv.value?.langTarget], ([_lSrc, _lTarget]) => {

        if (myLanguages.value.length !== 2) return nextTick(() => unwatchLang())
        if (serv.value)
          if (_lSrc && !_lTarget) {
            serv.value.langTarget = myLanguages.value.find(l => l.code !== _lSrc)!.code
            return nextTick(() => unwatchLang())
          } else if (_lTarget && !_lSrc) {
            serv.value.langSrc = myLanguages.value.find(l => l.code !== _lTarget)!.code
            return nextTick(() => unwatchLang())
          }
      }, { immediate: true })
    }

    const units = [$enum.ServiceUnit.PRICE_GRID, $enum.ServiceUnit.WORD, $enum.ServiceUnit.HOUR, $enum.ServiceUnit.DAY, $enum.ServiceUnit.PACKAGE]
    if (isInvoice())
      units.push($enum.ServiceUnit.GENERIC)

    return {
      isCatalog,
      isProject,
      isInvoice,

      units,

      serv: serv as Ref<_Service>,
      serv$,

      percents: percents as Ref<PriceGridPercent>,

      myLanguages,
      selectLangRequired: computed(() => !serv.value?.id && (serv.value?.unit === Service.ServiceUnit.PRICE_GRID || serv.value?.unit === Service.ServiceUnit.WORD)),
      selectLangDisabled: computed(() => !props.add && (serv.value?.unit === Service.ServiceUnit.PRICE_GRID || serv.value?.unit === Service.ServiceUnit.WORD)),
      switchLangs() {
        if (!serv.value?.langSrc || !serv.value?.langTarget) return
        const _s = serv.value.langSrc
        const _t = serv.value.langTarget
        serv.value.langSrc = _t
        serv.value.langTarget = _s
      },

      forceDismiss: $modal.forceDismiss,

      async save() {
        const s = serv.value as IService
        if (!isInvoice() && s.unit === Service.ServiceUnit.PRICE_GRID)
          delete s.value
        else
          delete s.valuePriceGrid

        if (s.company)
          s.company = { id: s.company.id }

        if (isCatalog() || (isProject() && props.add)) {
          if (s.id) {
            await $api.service.update(s)
          } else {
            const _s = await $api.service.add(s)
            s.id = _s.id
          }
        }

        $modal.forceDismiss(s)
      },

      changeType(unit: Service.ServiceUnit) {
        if (!unit || !serv.value || serv.value.id) return
        serv.value.unit = unit
      }
    }
  }
})


function duplicateService(service: _Service) {
  const _service = JSON.parse(JSON.stringify(service)) as _Service
  delete _service.id
  delete _service.ref
  if (!_service.valuePriceGrid) _service.valuePriceGrid = {}
  delete _service.valuePriceGrid.id
  return _service
}

function priceGridToPercents(v: PriceGrid) {
  return PRICE_GRID_ATTRS.reduce((perc, key) => {
    if (v[key] && v.basePrice)
      perc[key] = (v[key]! / v.basePrice) * 100;
    else
      perc[key] = 0;
    return perc;
  }, {} as PriceGridPercent)
}

function percentsToPriceGrid(basePrice = 0, perc: PriceGridPercent) {
  return PRICE_GRID_ATTRS.reduce((pg, key) => {
    pg[key] = roundPriceGridValue(basePrice * perc[key] / 100)
    return pg
  }, { basePrice } as PriceGridPercent & { basePrice: number; })
}

function roundPriceGridValue(v = 0) {
  return parseFloat(v.toFixed(9))
}
</script>
