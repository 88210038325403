import { ref, Ref } from 'vue';

import { IInvoice, Invoice, Project, InvoiceProject, InvoiceParam, Company } from '@tolemac/grpc_web_api/tolemac/pub/model';

import { useValidation } from '@tolemac/web-components';

import InvoiceModal from '@/components/invoice/InvoiceModal.vue'

import { objectRemoveKeys, objectReplaceId, today } from '@/util'
import { mapToInvoiceItem } from './util.service';

import { modalController } from '../modal';
import { toastController } from '../toast';
import { useApi } from '../api';
import { useStoreAccount } from '../store';

import { validators } from './util.validators'

const Status = Invoice.InvoiceStatus

type _InvoiceProject = RequireOne<InvoiceProject, 'project' | 'items'>
type _Invoice = Omit<Invoice, 'projects'> & { projects: _InvoiceProject[] }

export const invoice = {

    /**
     * @returns true, if there is a change in the status of the invoice
     */
    openModal(invoiceId: number, invoiceStatus?: Invoice.InvoiceStatus, noAction = false): Promise<boolean | undefined> {
        return modalController.openCustom({
            component: InvoiceModal,
            cssClass: 'fit',
            componentProps: { invoiceId, invoiceStatus, noAction }
        })
    },

    useInvoiceActions(refInvoice?: Ref<IInvoice | null>, opt: ActionsOpts = {}) {
        const $api = useApi()

        function argOrGlobal(_invoice?: IInvoice, clone = false): IInvoice {
            if (_invoice instanceof Event) _invoice = undefined

            if (!_invoice && refInvoice?.value) _invoice = refInvoice.value
            if (!_invoice) throw new Error('invoice undefined for useActionsInvoice')

            if (!clone) return _invoice
            return JSON.parse(JSON.stringify(_invoice)) satisfies IInvoice
        }

        function cleanInvoice(_invoice?: IInvoice) {
            objectRemoveKeys(_invoice, 'stats', 'projects.stats')
            objectReplaceId(_invoice, 'company', 'projects.project')
        }

        return {
            async save(invoice?: IInvoice, _onSave?: ActionsOpts['onSave']) {
                invoice = argOrGlobal(invoice, true)

                if (!invoice.projects) invoice.projects = []

                // remove project without invoice items
                invoice.projects = invoice.projects.filter(g => g.items?.length)

                // re index project and invoice items
                for (let i = 0; i < invoice.projects.length; i++) {
                    invoice.projects[i].index = (i + 1);
                    for (let j = 0; j < invoice.projects[i].items!.length; j++)
                        invoice.projects[i].items![j].index = (j + 1);
                }

                invoice.status = invoice.status === Status.SCHEDULED ? Status.DRAFT : invoice.status

                cleanInvoice(invoice)
                const invoiceSaved = await $api.invoice.save(invoice)

                await toastController.info("enregistré")

                if (_onSave) _onSave(invoiceSaved)
                else if (opt.onSave) opt.onSave(invoiceSaved)
            },

            async saveAndDeliver(invoice?: IInvoice, _onSave?: ActionsOpts['onSave']) {
                invoice = argOrGlobal(invoice, true)

                const confirm = await modalController.open({
                    title: 'Facturer le client',
                    message: 'Attention, vous ne pourrez plus éditer votre facture après confirmation.',
                    iconColor: 'green',
                    icon: 'check',
                    buttons: [{
                        title: 'Confirmer',
                        value: true,
                        color: 'primary'
                    }, {
                        title: 'Annuler'
                    }]
                })
                if (!confirm) return;

                invoice.status = Status.DELIVERED
                cleanInvoice(invoice)
                const invoiceSaved = await $api.invoice.save(invoice)

                if (_onSave) _onSave(invoiceSaved)
                else if (opt.onSave) opt.onSave(invoiceSaved)
            },

            async deliver(invoice?: IInvoice, _onDeliver?: ActionsOpts['onDeliver']) {
                invoice = argOrGlobal(invoice)

                const confirm: false | number = await modalController.openCustom({
                    component: (await import('@/components/invoice/InvoiceModalDeliver.vue')).default,
                })
                if (!confirm) return;

                await $api.invoice.update({
                    id: invoice.id,
                    status: Status.DELIVERED,
                    date: typeof confirm === "number" ? confirm : undefined
                })

                if (_onDeliver) _onDeliver()
                else if (opt.onDeliver) opt.onDeliver()
            },

            async deliverWithDate(lastInvoiceDate: number, invoice?: IInvoice, _onDeliver?: ActionsOpts['onDeliver']) {
                invoice = argOrGlobal(invoice)

                const confirm: false | number = await modalController.openCustom({
                    component: (await import('@/components/invoice/InvoiceModalDeliver.vue')).default,
                    componentProps: { lastInvoiceDate }
                })
                if (!confirm) return;

                await $api.invoice.update({
                    id: invoice.id,
                    status: Status.DELIVERED
                })

                if (_onDeliver) _onDeliver()
                else if (opt.onDeliver) opt.onDeliver()
            },

            async pay({ onPay, archiveProject, invoice }: { onPay?: ActionsOpts['onPay']; archiveProject?: true | string[]; invoice?: IInvoice } = {}) {
                invoice = argOrGlobal(invoice)

                const confirm: false | number = await modalController.openCustom({
                    component: (await import('@/components/invoice/InvoiceModalPay.vue')).default,
                    componentProps: { archiveProject }
                })
                if (!confirm) return;

                await $api.invoice.update({
                    id: invoice.id,
                    payDate: confirm,
                    status: Status.PAID
                })

                if (onPay) onPay()
                else if (opt.onPay) opt.onPay()
            },

            async delete(invoice?: IInvoice, _onDelete?: ActionsOpts['onDelete']) {
                invoice = argOrGlobal(invoice)

                if (await modalController.open({
                    title: 'Supprimer la facture',
                    message: [
                        'Êtes-vous certain de vouloir supprimer la nouvelle facture ?',
                        '',
                        'Elle sera supprimée définitivement de votre tableau de facturation.'
                    ],
                    iconColor: 'danger',
                    buttons: [{
                        title: 'Supprimer',
                        value: true,
                        color: 'red'
                    }, {
                        title: 'Annuler'
                    }]
                })) {
                    await $api.invoice.delete({ id: invoice.id })

                    await toastController.info("supprimé")

                    if (_onDelete) _onDelete()
                    else if (opt.onDelete) opt.onDelete()
                }
            }
        }
    },

    useInvoiceLoader(extraRelations: string[] = []) {
        const $api = useApi();
        const $storeAccount = useStoreAccount()

        const apiRelations = ['projects.items', 'projects.project.services', ...extraRelations]

        const lastInvoiceDate = ref<number>()

        const invoice$args = ref(validators.invoice.init({ today: today() }))
        const invoice = ref<_Invoice | null>(null)
        const { validationObj: invoice$, setVal: invoice$set, resetVal: invoice$reset } = useValidation(invoice, invoice$args)

        function addProject(p: Project, _invoice?: Invoice) {
            if (!_invoice) _invoice = invoice.value || undefined
            if (!_invoice) return

            let keepQuantity = false
            if (!p.stats)
                console.warn('project stats loaded');
            else
                keepQuantity = p.stats.amountInDraft === 0 && p.stats.amountDelivered === 0 && p.stats.amountLate === 0 && p.stats.amountPaid === 0

            const projectToAddItems = (p.services || []).map(_s => mapToInvoiceItem(_s, keepQuantity))

            if (!_invoice.projects) _invoice.projects = []

            _invoice.projects.push({
                project: p,
                index: _invoice.projects.length,
                items: projectToAddItems
            })

            if (!_invoice.company)
                _invoice.company = p.company
            else if (_invoice.company.id !== p.company?.id)
                throw new Error('An invoice can only have projects from the same company')
        }

        async function loadProjectsOnInvoice(_invoice: _Invoice, companyId?: number, projectIds: number[] = []) {
            if (!companyId && !projectIds) return

            let projectsToAdd: Project[] = []

            if (projectIds.length) {

                const projectLoaded = await Promise.all(
                    projectIds.map(projectId => $api.project.get({ id: projectId, relations: ['services', 'company', 'invoicesProjectStats'] }))
                )

                // create invoice with selected project
                projectsToAdd.push(...projectLoaded.filter(pp => !!pp))

            } else if (companyId) {
                // create invoice with selected company
                const [_company, _projects] = await Promise.all([
                    $api.company.get({ id: companyId }),
                    $api.project.find({
                        criterias: [
                            { field: 'status', values: [`${Project.ProjectStatus.WIP__NEXT}`, `${Project.ProjectStatus.WIP}`, `${Project.ProjectStatus.DELIVERED}`] },
                            { field: 'company.id', value: `${companyId}` }
                        ],
                        relations: ['services', '@company', 'invoicesProjectStats']
                    })
                ])
                _invoice.company = _company
                if (_projects.values.length)
                    projectsToAdd.push(..._projects.values)
            }

            for (const projectToAdd of (projectsToAdd || []))
                addProject(projectToAdd, _invoice)
        }

        async function findLastInvoiceDate() {
            const _lastInvoices = await $api.invoice.find({
                criterias: [{ field: 'status', values: [`${Status.SCHEDULED}`, `${Status.DRAFT}`], not: true }],
                limit: 1,
                select: ['date'],
                orders: [{ field: 'date', direction: 2 }]
            })

            if (_lastInvoices.values.length) {
                const _lastInvoice = _lastInvoices.values[0].date!
                return _lastInvoice
            }
            return null
        }

        function loadSettingsOnInvoice(_invoice: _Invoice, _invoiceParam: InvoiceParam, _company?: Company) {

            if (_company) {
                if (_company.addressLine1) _invoice.addressLine1 = _company.addressLine1;
                if (_company.addressLine2) _invoice.addressLine2 = _company.addressLine2;
                if (_company.postalCode) _invoice.postalCode = _company.postalCode;
                if (_company.city) _invoice.city = _company.city;
                if (_company.state) _invoice.state = _company.state;
                if (_company.country) _invoice.country = _company.country;

                if (!_company.paymentDeadline) {
                    _invoice.paymentDeadline = _invoiceParam.deadline
                    _invoice.paymentDeadlineEndMonth = _invoiceParam.deadlineEndMonth
                } else {
                    _invoice.paymentDeadline = _company.paymentDeadline
                    _invoice.paymentDeadlineEndMonth = _company.paymentDeadlineEndMonth
                }
            }

            _invoice.paymentCondition = _invoiceParam.paymentCondition
            _invoice.mandatoryInformation = _invoiceParam.legalNotice
        }

        async function loadInvoice(id?: number, companyId?: number, projectsId: number[] = []) {
            let _invoice: _Invoice | undefined = undefined
            let _lastInvoice: number | null | undefined = undefined

            if (id) {
                // load invoice
                _invoice = await $api.invoice.get({ id, relations: apiRelations }) as _Invoice
                _lastInvoice = await findLastInvoiceDate()
                const isDelivered = !!_invoice.status && _invoice.status > Invoice.InvoiceStatus.DRAFT
                validators.invoice.update(invoice$args.value, { lastInvoice: _lastInvoice, dateIsEditable: !isDelivered, today: today() })

                if (_invoice.status === Status.SCHEDULED) {
                    // prepare invoice scheduled
                    companyId = _invoice.company!.id
                    projectsId = [_invoice.projects[0].project.id!]
                    _invoice.projects = []
                }

            }

            if (!id || (_invoice && _invoice.status === Status.SCHEDULED)) {

                // new invoice or invoice scheduled pre loaded
                if (!_invoice) _invoice = {
                    status: Status.DRAFT,
                    date: today(),
                    projects: []
                };

                [_lastInvoice] = await Promise.all([
                    _lastInvoice === undefined ? findLastInvoiceDate() : _lastInvoice,
                    loadProjectsOnInvoice(_invoice, companyId, projectsId)
                ])

                loadSettingsOnInvoice(_invoice, $storeAccount.user!.orga.invoiceParam, _invoice.company)
            }

            lastInvoiceDate.value = _lastInvoice ? _lastInvoice : undefined
            await invoice$set(_invoice)

            // check date on scheduled invoice
            if (invoice.value && invoice.value.status === Status.SCHEDULED && invoice.value.date && !invoice$.value.date.$validate()) {
                invoice.value.date = today()
                await invoice$set()
            }

            return _invoice!
        }

        return {
            invoice,
            invoice$,
            invoice$args,
            invoice$set,
            invoice$reset,

            lastInvoiceDate,

            loadInvoice,
            addProject: addProject as (p: Project) => void,
        }
    }
}

type ActionsOpts = {
    refLastInvoiceDate?: Ref<number | undefined>

    onSave?: (invoice: IInvoice) => void;
    onSend?: () => void;
    onPay?: () => void;
    onDeliver?: () => void;
    onDelete?: () => void;
}